/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  loading: false,
  errorMsg: "",
  incidents: {},
  incidentDetail: null,
  assetsList: null,
  files: {},
  users: {},
  userLoading: false,
  incidentLoading: false,
};

const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get Incident List -- */
      .addCase(getIncidents.pending, (state) => {
        state.incidentLoading = true;
        state.errorMsg = "";
      })
      .addCase(getIncidents.fulfilled, (state, action) => {
        state.incidentLoading = false;
        state.incidents = action.payload;
      })
      .addCase(getIncidents.rejected, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get all Assets -- */
      .addCase(getAllAssets.pending, (state) => {
        state.incidentLoading = true;
        state.errorMsg = "";
      })
      .addCase(getAllAssets.fulfilled, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = "";
        state.assetsList = action.payload;
      })
      .addCase(getAllAssets.rejected, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Create Incident -- */
      .addCase(createIncident.pending, (state) => {
        state.incidentLoading = true;
        state.errorMsg = "";
      })
      .addCase(createIncident.fulfilled, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = "";
      })
      .addCase(createIncident.rejected, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Incident related Task -- */
      .addCase(incidentTask.pending, (state) => {
        state.incidentLoading = true;
        state.errorMsg = "";
      })
      .addCase(incidentTask.fulfilled, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = "";
      })
      .addCase(incidentTask.rejected, (state, action) => {
        state.incidentLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get Incident Detail -- */
      .addCase(getIncidentDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getIncidentDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.incidentDetail = action.payload;
      })
      .addCase(getIncidentDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Update Incident Detail -- */
      .addCase(updateIncidentDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateIncidentDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateIncidentDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Delete Incident File -- */
      .addCase(deleteIncidentFile.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteIncidentFile.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteIncidentFile.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- create incident task -- */
      .addCase(createIncidentTask.pending, (state) => {
        state.taskLoading = true;
        state.errorMsg = "";
      })
      .addCase(createIncidentTask.fulfilled, (state, action) => {
        state.taskLoading = false;
        state.errorMsg = "";
      })
      .addCase(createIncidentTask.rejected, (state, action) => {
        state.taskLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Filter incident -- */
      .addCase(filterIncidents.pending, (state) => {
        state.taskLoading = true;
        state.errorMsg = "";
      })
      .addCase(filterIncidents.fulfilled, (state, action) => {
        state.taskLoading = false;
        state.errorMsg = "";
        state.incidents = action.payload;
      })
      .addCase(filterIncidents.rejected, (state, action) => {
        state.taskLoading = false;
        // state.errorMsg = action.payload.error;
      });
  },
});

/* --- Get all incidents -- */
export const getIncidents = createAsyncThunk(
  "incident/getIncidents",
  async ({ nextUrl } = {}, { rejectWithValue }) => {
    try {
      let url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/?limit=20`;
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all Assets -- */
export const getAllAssets = createAsyncThunk(
  "incident/getAllAssets",
  async (_, { rejectWithValue }) => {
    let query = "default";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/?asset_type=${query}&limit=500`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Filter -- */
export const filterIncidents = createAsyncThunk(
  "incident/filterIncidents",
  async (searchItem, { rejectWithValue }) => {
    const { query, type } = searchItem;
    let url;
    if (type === "filterByTeam") {
      url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/?assignedTo=${query}&limit=500`;
    } else if (type === "filterByStatus") {
      url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/?status=${query}&limit=500`;
    } else if (type === "filterBySeverity") {
      url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/?severity=${query}&limit=500`;
    } else if (type === "filterByRange") {
      const [startDate, endDate] = query.split(" - ");
      url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/?dateAfter=${startDate}&dateBefore=${endDate}&limit=500`;
    }
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Create incidents -- */
export const createIncident = createAsyncThunk(
  "incident/createIncident",
  async (incidentData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/`,
        incidentData,
        {
          withCredentials: true,
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Incident related Task -- */
export const incidentTask = createAsyncThunk(
  "incident/incidentTask",
  async (taskData, { rejectWithValue }) => {
    console.log("Task data at slice", taskData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/`,
        taskData,
        {
          withCredentials: true,
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get incident details -- */
export const getIncidentDetail = createAsyncThunk(
  "incident/getIncidentDetail",
  async (incidentId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/${incidentId}/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Update incident -- */
export const updateIncidentDetail = createAsyncThunk(
  "incident/updateIncidentDetail",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/${id}/`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Delete incident -- */
export const deleteIncidentFile = createAsyncThunk(
  "incident/deleteIncidentFile",
  async (fileId, { rejectWithValue }) => {
    console.log("In slice", fileId);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/files/${fileId}/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const createIncidentTask = createAsyncThunk(
  "incident/createIncidentTask",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("task_name", data.task_name);
      formData.append("description ", data.description);
      formData.append("incident", data.incident);
      formData.append("source", data.source);
      formData.append("severity", data.severity);
      formData.append("status", data.status);
      formData.append("due_date", data.due_date);
      formData.append("is_review_required", data.is_review_required);
      formData.append("created_by", data.created_by);
      formData.append("assigned_to", data.assigned_to);

      data.files.forEach((file, index) => {
        const blob = new Blob([file], { type: file.type });
        formData.append(`task_files`, blob, file.name);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/`,
        formData,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getUsers = createAsyncThunk(
  "companyData/get_users",
  async (params, { rejectWithValue }) => {
    try {
      const _params = new URLSearchParams(params).toString();

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/users?${_params}`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = incidentSlice.actions;
export default incidentSlice.reducer;
