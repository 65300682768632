import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loader from "../common/loader/Loader";
import { getCompanyPreviousDetails } from "../features/user-onboarding/userOnboardingSlice";
import { getCompanies } from "../features/dashboard/companies/companiesSlice";
import { setCurrentCompany } from "../store/commonSlice";
import { routeToAllowedPath } from "../utils/functions";
import { USER_ROLES } from "../utils/constant";

const WithAuthRedirect = ({ element }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isReloading = useSelector((state) => state.login.isReloading);
  const currentCompany = useSelector((state) => state.common.currentCompany);
  const [consultantCompanyLoading, setConsultantCompanyLoading] =
    useState(true);

  const companyDetails = useSelector(
    (state) => state.onboarding.companyDetails,
  );
  const { userDetails } = useSelector((state) => state.login);
  const { loading: isCompaniesLoading, companies: companiesData } = useSelector(
    (state) => state.companies,
  );
  const companiesList = companiesData?.results || [];

  const setSelectedCompany = async () => {
    if (!currentCompany && !isCompaniesLoading && companiesList?.length) {
      const companyId = localStorage.getItem("companyId");
      let selectedCompany = null;
      if (companyId) {
        selectedCompany = companiesList.find(
          ({ company, isRelationActive }) =>
            company.id === companyId && isRelationActive,
        );
      }
      if (!selectedCompany) {
        selectedCompany = companiesList.find(
          ({ isRelationActive }) => isRelationActive,
        );
      }
      await dispatch(setCurrentCompany(selectedCompany));
      setConsultantCompanyLoading(false);
    } else if (!isCompaniesLoading && Object.keys(companiesData || {}).length) {
      setConsultantCompanyLoading(false);
    }
  };

  useEffect(() => {
    setSelectedCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesData]);

  useEffect(() => {
    if (isLoggedIn && userDetails.role === USER_ROLES.CONSULTANT) {
      dispatch(getCompanies());
    } else if (isLoggedIn && userDetails.role !== USER_ROLES.CONSULTANT) {
      setConsultantCompanyLoading(false);
    } else if (!isReloading && !isLoggedIn) {
      setConsultantCompanyLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isReloading]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCompanyPreviousDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && Object.keys(userDetails || {}).length) {
      routeToAllowedPath(
        location.pathname,
        userDetails,
        companyDetails,
        currentCompany,
        navigate,
        consultantCompanyLoading,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn,
    companyDetails,
    userDetails,
    currentCompany,
    consultantCompanyLoading,
  ]);

  // If loading, you can render a loading spinner or message
  if (isReloading || consultantCompanyLoading) {
    return <Loader />;
  }

  // // If not logged in, redirect to login page
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // If logged in, render the protected component
  return element;
};

export default WithAuthRedirect;
