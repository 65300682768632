/* eslint-disable no-undef */
import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isCompaniesLoading: false,
  isConsultantLoading: false,
  errorMsg: "",
  companies: {},
  companyDetail: {},
  compliances: {},
  consultants: {},
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get companies list -- */
      .addCase(getCompanies.pending, (state) => {
        state.isCompaniesLoading = true;
        state.errorMsg = "";
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isCompaniesLoading = false;
        state.errorMsg = "";
        state.companies = action.payload;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.isCompaniesLoading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get company detail -- */
      .addCase(getCompanyDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCompanyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.companyDetail = action.payload;
      })
      .addCase(getCompanyDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- add company -- */
      .addCase(inviteCompany.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(inviteCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(inviteCompany.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- send invitation to company -- */
      .addCase(sendInvitation.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(sendInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(sendInvitation.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Update company detail -- */
      .addCase(updateCompanyDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateCompanyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateCompanyDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get compliance list -- */
      .addCase(getCompliances.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCompliances.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.compliances = action.payload;
      })
      .addCase(getCompliances.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get consultants -- */
      .addCase(getConsultants.pending, (state) => {
        state.isConsultantLoading = true;
        state.errorMsg = "";
      })
      .addCase(getConsultants.fulfilled, (state, action) => {
        state.isConsultantLoading = false;
        state.errorMsg = "";
        state.consultants = action.payload;
      })
      .addCase(getConsultants.rejected, (state, action) => {
        state.isConsultantLoading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Update consultant detail -- */
      .addCase(updateConsultantDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateConsultantDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateConsultantDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getCompanies = createAsyncThunk(
  "companies/get_companies",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/companies/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCompanyDetail = createAsyncThunk(
  "companies/get_company_detail",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/company-detail/`;

    try {
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          Company: id,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const inviteCompany = createAsyncThunk(
  "companies/invite_company",
  async ({ data }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/invite-company/`;

    try {
      const response = await axios.post(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const sendInvitation = createAsyncThunk(
  "companies/send_invitation",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/send-invitation/`;

    try {
      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {
          Company: id,
        },
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateCompanyDetail = createAsyncThunk(
  "companies/update_company_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/company/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
        company: id,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCompliances = createAsyncThunk(
  "companies/get_compliances",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/compliances/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getConsultants = createAsyncThunk(
  "companies/get_consultants",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/consultants/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateConsultantDetail = createAsyncThunk(
  "companies/update_consultant_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/consultants/${id}/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
        company: id,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = companiesSlice.actions;
export default companiesSlice.reducer;
