/* eslint-disable no-undef */
import axios from "../../../utils/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  securityProcesses: [],
  measures: {},
  measureDetail: null,
  loading: false,
  isSecurityProcessLoading: false,
  isMeasuresLoading: false,
  errorMsg: "",
};

const measuresSlice = createSlice({
  name: "measures",
  initialState,
  reducers: {
    resetMeasures: (state) => {
      state.measures = {};
    },
    resetSecurityProcesses: (state) => {
      state.securityProcesses = {};
      state.measures = {};
    },
    resetState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- get security process -- */
      .addCase(getSecurityProcesses.pending, (state) => {
        state.isSecurityProcessLoading = true;
        state.errorMsg = "";
      })
      .addCase(getSecurityProcesses.fulfilled, (state, action) => {
        state.isSecurityProcessLoading = false;
        state.errorMsg = "";
        state.securityProcesses = action.payload;
      })
      .addCase(getSecurityProcesses.rejected, (state, action) => {
        state.isSecurityProcessLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get measures associated with a security process -- */
      .addCase(getMeasures.pending, (state) => {
        state.isMeasuresLoading = true;
        state.errorMsg = "";
      })
      .addCase(getMeasures.fulfilled, (state, action) => {
        state.isMeasuresLoading = false;
        state.errorMsg = "";
        state.measures = action.payload;
      })
      .addCase(getMeasures.rejected, (state, action) => {
        state.isMeasuresLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get measure detail -- */
      .addCase(getMeasureDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getMeasureDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.measureDetail = action.payload;
      })
      .addCase(getMeasureDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- update measure detail -- */
      .addCase(updateMeasureDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateMeasureDetail.fulfilled, (state) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateMeasureDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- update measure type -- */
      .addCase(updateMeasureType.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateMeasureType.fulfilled, (state) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateMeasureType.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getSecurityProcesses = createAsyncThunk(
  "measures/get_security_processes",
  async ({ nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/security-processes/?limit=20`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getMeasures = createAsyncThunk(
  "measures/get_measures",
  async ({ measureId, nextUrl, measureType }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/security-processes/${measureId}/measures/?measure_type=${measureType}&limit=30`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getMeasureDetail = createAsyncThunk(
  "measures/get_measure_detail",
  async (measureId, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}api/v1/security-processes/measure/${measureId}`;
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateMeasureDetail = createAsyncThunk(
  "measures/update_measure_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/security-processes/measure/${id}/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

// move measure from recommended to implemented
export const updateMeasureType = createAsyncThunk(
  "measures/update_measure_type",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/security-processes/measure/${id}/implement/`;
    const data = {
      measure_type: "implemented",
    };

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, resetMeasures, resetSecurityProcesses } =
  measuresSlice.actions;

export default measuresSlice.reducer;
