import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const company = localStorage.getItem("companyId");

    config.headers = {
      Company: company,
      ...config.headers,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
