import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  loading: false,
  errorMsg: "",
  documentList: null,
  headDocumentDetail: null,
  subDocumentDetail: null,
  userList: [],
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- GET document -- */
      .addCase(fetchDocument.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(fetchDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.documentList = action.payload.results;
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- GET head document detail -- */
      .addCase(fetchHeadDocumentDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(fetchHeadDocumentDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.headDocumentDetail = action.payload;
      })
      .addCase(fetchHeadDocumentDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- GET sub document detail -- */
      .addCase(fetchSubDocumentDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(fetchSubDocumentDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.subDocumentDetail = action.payload;
      })
      .addCase(fetchSubDocumentDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Export PDF head document -- */
      .addCase(exportPDFHeadDoc.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(exportPDFHeadDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(exportPDFHeadDoc.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Export PDF sub document -- */
      .addCase(exportPDFSubDoc.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(exportPDFSubDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(exportPDFSubDoc.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Search document -- */
      .addCase(searchDocument.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(searchDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.documentList = action.payload.results;
      })
      .addCase(searchDocument.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Sorting by document table header -- */
      .addCase(sortingDocument.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(sortingDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.documentList = action.payload.results;
      })
      .addCase(sortingDocument.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Re-ordering Approved tab document in a table -- */
      .addCase(reOrderingDocumentPosition.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(reOrderingDocumentPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        //state.documentList = action.payload.results;
      })
      .addCase(reOrderingDocumentPosition.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Sub document edit -- */
      .addCase(subDocumentEdit.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(subDocumentEdit.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(subDocumentEdit.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Head document edit -- */
      .addCase(headDocumentEdit.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(headDocumentEdit.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(headDocumentEdit.rejected, (state, action) => {
        state.loading = false;

        state.errorMsg = action.payload.error;
      })

      /* --- Users -- */
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.userList = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- GET document -- */
export const fetchDocument = createAsyncThunk(
  "document/fetchDocument",
  async (documentType, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/?document_status=${documentType}&limit=100`,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- GET head document detail -- */
export const fetchHeadDocumentDetail = createAsyncThunk(
  "document/fetchHeadDocumentDetail",
  async (documentID, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/${documentID}/`,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- GET sub document detail -- */
export const fetchSubDocumentDetail = createAsyncThunk(
  "document/fetchSubDocumentDetail",
  async (documentID, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/sub-documents/${documentID}/`,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Head document export PDF -- */
export const exportPDFHeadDoc = createAsyncThunk(
  "document/exportPDFHeadDoc",
  async (documentID, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/${documentID}/`,
        null, // Empty body
        {
          withCredentials: true,
          responseType: "blob", // response is a Blob
        },
      );

      return response.data; // Blob is directly returned
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Sub document export PDF -- */
export const exportPDFSubDoc = createAsyncThunk(
  "document/exportPDFSubDoc",
  async (documentID, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/sub-documents/${documentID}/`,
        null, // Empty body
        {
          withCredentials: true,
          responseType: "blob", // response is a Blob
        },
      );

      return response.data; // Blob is directly returned
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Search document -- */
export const searchDocument = createAsyncThunk(
  "document/searchDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/?search=${data.debouncedSearchTerm}&document_status=${data.documentStatus}&limit=100`,

        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);
// sortBy="name,date"
/* --- Sorting by document table header -- */
export const sortingDocument = createAsyncThunk(
  "document/sortingDocument",
  async (sortBy, { rejectWithValue }) => {
    let documentStatus = "archived";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/?ordering=${sortBy}&document_status=${documentStatus}&limit=100`,

        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Re-ordering Approved tab document in a table -- */
export const reOrderingDocumentPosition = createAsyncThunk(
  "document/reOrderingDocumentPosition",
  async (data, { rejectWithValue }) => {
    let requestBody = {
      ordering: data.index,
    };
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/${data.id}/`,
        requestBody,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all Users -- */
export const getUsers = createAsyncThunk(
  "assessment/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/users/?limit=500`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* ---Sub document edit -- */
export const subDocumentEdit = createAsyncThunk(
  "document/subDocumentEdit",
  async (documentID, { rejectWithValue }) => {
    let responseBody = { sub_document_status: "draft" };
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/sub-documents/${documentID}/`,
        responseBody,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* ---Head document edit -- */
export const headDocumentEdit = createAsyncThunk(
  "document/headDocumentEdit",
  async (data, { rejectWithValue }) => {
    try {
      // Destructure the 'id' from 'data' so it's excluded from the request body
      const { id, ...requestBody } = data;

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/${id}/`, // Use 'id' in the URL
        requestBody, // Only the remaining fields will be sent as the body
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* ---Head document approve -- */
export const headDocumentApprove = createAsyncThunk(
  "document/headDocumentEdit",
  async (data, { rejectWithValue }) => {
    try {
      const { id, ...requestBody } = data;
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/${id}/`,
        requestBody,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* ---Sub document approve -- */
export const subDocumentApprove = createAsyncThunk(
  "document/headDocumentEdit",
  async (id, { rejectWithValue }) => {
    try {
      let requestBody = {
        sub_document_status: "approved",
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/security-policies/sub-documents/${id}/`,
        requestBody,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = documentSlice.actions;
export default documentSlice.reducer;
