/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  loading: false,
  errorMsg: "",
  tasks: {},
  myTasks: {},
  taskDetail: {},
  parentTaskDetail: {},
  files: {},
  taskLoading: false,
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
    setTaskDetail(state, action) {
      state.taskDetail.measure = action.payload;
      return state;
    },
    resetTaskState(state) {
      // Reset state to the initial state
      return {
        ...state,
        taskDetail: initialState.taskDetail,
      };
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get task List -- */
      .addCase(getTasks.pending, (state) => {
        state.taskLoading = true;
        state.errorMsg = "";
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.taskLoading = false;
        state.errorMsg = "";
        state.tasks = action.payload;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.taskLoading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get task Detail -- */
      .addCase(getTaskDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getTaskDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        const taskData = action.payload;
        state.taskDetail[taskData.id] = taskData;
      })
      .addCase(getTaskDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get task Series Detail -- */
      .addCase(getTaskSeriesDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getTaskSeriesDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.parentTaskDetail = action.payload;
      })
      .addCase(getTaskSeriesDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Update task Detail -- */
      .addCase(updateTaskDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateTaskDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateTaskDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Update task Series -- */
      .addCase(updateTaskSeries.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateTaskSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateTaskSeries.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Delete task File -- */
      .addCase(deleteTaskFile.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteTaskFile.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteTaskFile.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- create task -- */
      .addCase(createTask.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(createTask.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action?.payload?.error;
      })
      /* --- review task -- */
      .addCase(reviewTask.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(reviewTask.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(reviewTask.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Delete task -- */
      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Delete task series -- */
      .addCase(deleteTaskSeries.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteTaskSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteTaskSeries.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getTasks = createAsyncThunk(
  "tasks/get_tasks",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey] && currentKey !== "dateRange") {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getTaskDetail = createAsyncThunk(
  "task/getTaskDetail",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${id}/`;

    try {
      const response = await axios.get(url, {
        withCredentials: true,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getTaskSeriesDetail = createAsyncThunk(
  "task/getTaskSeriesDetail",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${id}/edit-series/`;

    try {
      const response = await axios.get(url, {
        withCredentials: true,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateTaskDetail = createAsyncThunk(
  "task/update_task_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${id}/`;

    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== "task_files" && data[key]) {
          formData.append(key, data[key]);
        }
      });

      data.task_files.forEach((file, index) => {
        const blob = new Blob([file], { type: file.type });
        formData.append(`task_files`, blob, file.name);
      });

      const response = await axios.patch(url, formData, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateTaskSeries = createAsyncThunk(
  "task/update_task_series",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${id}/edit-series/`;

    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== "task_files" && key !== "existing_files" && data[key]) {
          formData.append(key, data[key]);
        }
      });

      if (data.existing_files) {
        data.existing_files.forEach((fileId) => {
          formData.append(`existing_files`, fileId);
        });
      }

      data.task_files.forEach((file, index) => {
        const blob = new Blob([file], { type: file.type });
        formData.append(`task_files`, blob, file.name);
      });

      const response = await axios.patch(url, formData, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteTaskFile = createAsyncThunk(
  "task/deleteTaskFile",
  async (fileId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/files/${fileId}/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const createTask = createAsyncThunk(
  "task/create_task",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key !== "task_files" && data[key]) {
          formData.append(key, data[key]);
        }
      });

      data.task_files.forEach((file, index) => {
        const blob = new Blob([file], { type: file.type });
        formData.append(`task_files`, blob, file.name);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/`,
        formData,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const reviewTask = createAsyncThunk(
  "task/review_task",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("status", data.status);
      if (data.comment) {
        formData.append("comment", data.comment);
      }

      if (data.task_files) {
        data.task_files.forEach((file) => {
          const blob = new Blob([file], { type: file.type });
          formData.append(`task_files`, blob, file.name);
        });
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${data.id}/review/`,
        formData,
        {
          withCredentials: true,
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteTask = createAsyncThunk(
  "task/delete_task",
  async (taskId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${taskId}/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteTaskSeries = createAsyncThunk(
  "task/delete_task_series",
  async (taskId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/${taskId}/edit-series/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, resetTaskState, setTaskDetail } = taskSlice.actions;
export default taskSlice.reducer;
