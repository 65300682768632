import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state for the common slice.
 */
const initialState = {
  errorMessage: "",
  infoMessage: "",
  currentCompany: "",
};

/**
 * Slice for managing common application state such as error and info messages.
 */
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setAppError: (state, action) => {
      state.errorMessage = action.payload;
    },
    setAppInfo: (state, action) => {
      state.infoMessage = action.payload;
    },
    setCurrentCompany: (state, action) => {
      state.currentCompany = action.payload?.company;
      localStorage.setItem("companyId", action.payload?.company?.id || "");
    },
  },
});

export const { setAppError, setAppInfo, setCurrentCompany } =
  commonSlice.actions;
export default commonSlice.reducer;
