import { createTheme } from "@mui/material/styles";
import { gridClasses } from "@mui/x-data-grid";

let theme = createTheme({
  palette: {
    primary: {
      main: "#6FC73D",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#53545C",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset",
          "&.MuiButton-colorError": {
            backgroundColor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#d32f2f",
              color: "#ffffff",
            },
          },
        },
        contained: {
          "&:hover": {
            backgroundColor: "#6FC73D",
          },
        },
        outlined: {
          backgroundColor: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#EBF8EF",
          },
        },
      },
    },
    MuiDataGrid: {
      containerBackground: "#000000",
      pinnedBackground: "#340606",
      styleOverrides: {
        root: {
          [`& .${gridClasses.columnHeaderTitle}`]: {
            fontWeight: "600",
            fontSize: "1rem",
          },
          [`& .${gridClasses.cell}`]: {
            border: "none",
            color: "#000000",
            fontSize: "0.875rem",
            letterSpacing: "0.009rem",
            "&:focus": {
              outline: "none",
            },
          },
          "&& .Mui-selected": {
            backgroundColor: "#EBF8EF",
          },
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});

// Define reusable color variables
const customColors = {
  secondary_custom: "#EBF8EF",
  info_custom: "#2F80ED",
  success_custom: "#27AE60",
  warning_custom: "#E2B93B",
  error_custom: "#EB5757",
  black1: "#000000",
  black2: "#1D1D1D",
  black3: "#282828",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray900: "#101828",
  gray700: "#344054",
  gray300: "#D0D5DD",
  gray400: "#98A2B3",
  darkGray: "#3A3541",
  lightGray: "#D3D7DE",
  regentGray: "#8692A6",
  davyGray: "#49505A",
  shaffieldGray: "#6A7381",
  naturalGray: "#fafafa",
  darkGrayBlue: "#2C2D33",
  greenishCyan: "#DFF1ED",
  navyBlue: "#092C4C",
  blackPearl: "#121417",
  yellowishOrange: "#FFC261",
  lightGrayishBlue: "#EDF0F5",
  blackGreen: "#101f20",
  greenWhite: "#dbf0dc",
  approxBlack: "#020202",
  lushGreen: "#77fa3f",
  lightGreen: "#D7FFC3",
  palePink: "#f9dbe0",
  whiteCustom: "#FFFFFF",
  fadedOrange: "#F2994A",
  torchRed: "#FF1B36",
  lightPink: "#FFCCCB",
  offWhite: "#FFFFE0",
  lavenderPurple: "#8E8EAF",
  midGreen: "#4fa94d",
  paleAqua: "#CCD1D9",
  application_bg: "#fafafa",
  mistBlue: "#667085",
  tealishBlue: "#475467",
  orangePeel: "#F9A000",
  redOrange: "#FF3B30",
  brand50: "#F2FBEA",
  brand500: "#5DB131",
};

theme = createTheme(theme, {
  palette: Object.keys(customColors).reduce((palette, colorKey) => {
    palette[colorKey] = theme.palette.augmentColor({
      color: { main: customColors[colorKey] },
      name: colorKey,
    });
    return palette;
  }, {}),
});

export default theme;
