import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  dashboardData: null,
  errorMsg: "",
};

const dashboardHomeSlice = createSlice({
  name: "dashboardHome",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Payment -- */
      .addCase(fetchLevel.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(fetchLevel.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.dashboardData = action.payload;
      })
      .addCase(fetchLevel.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Dashboard home LEVEL -- */
export const fetchLevel = createAsyncThunk(
  "payment/fetchLevel",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/home/`,

        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = dashboardHomeSlice.actions;
export default dashboardHomeSlice.reducer;
