import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/login/authSlice";
import signUpReducer from "../features/sign-up/signUpSlice";
import resetPasswordReducer from "../features/reset-password/resetSlice";
import paymentReducer from "../features/payment/paymentSlice";
import onboardingReducer from "../features/user-onboarding/userOnboardingSlice";
import settingReducer from "../features/dashboard/settings/settingSlice";
import incidentReducer from "../features/dashboard/incidents/incidentSlice";
import taskReducer from "../features/dashboard/tasks/taskSlice";
import assetReducer from "../features/dashboard/assets/assetSlice";
import commonReducer from "./commonSlice";
import assessmentReducer from "../features/dashboard/assessment/assessmentSlice";
import riskSlice from "../features/dashboard/risks/riskSlice";

import documentSlice from "../features/dashboard/document-management/documentSlice";
import dashboardHomeSlice from "../features/dashboard/dashboard-home/dashboardHomeSlice";

import measuresReducer from "../features/dashboard/measures/measuresSlice";
import companiesReducer from "../features/dashboard/companies/companiesSlice";

const store = configureStore({
  reducer: {
    signUp: signUpReducer,
    login: authReducer,
    resetPassword: resetPasswordReducer,
    payment: paymentReducer,
    onboarding: onboardingReducer,
    setting: settingReducer,
    incident: incidentReducer,
    common: commonReducer,
    task: taskReducer,
    asset: assetReducer,
    assessment: assessmentReducer,
    risk: riskSlice,

    document: documentSlice,
    dashboardHome: dashboardHomeSlice,

    measures: measuresReducer,
    companies: companiesReducer,
  },
});

export default store;
